const roundUpToHour = (date) => {
  const p = 60 * 60 * 1000 // milliseconds in an hour
  return new Date(Math.ceil(date.getTime() / p) * p)
}

const roundDownToHour = (date) => {
  const p = 60 * 60 * 1000 // milliseconds in an hour
  return new Date(Math.round(date.getTime() / p) * p)
}

const roundToNearestHour = (date) => {
  const p = 60 * 60 * 1000 // milliseconds in an hour
  return new Date(Math.round(date.getTime() / p) * p)
}

const dateInPast = (firstDate) => {
  const a = firstDate.setHours(0, 0, 0, 0)
  const today = new Date()
  const b = today.setHours(0, 0, 0, 0)
  return a < b
}

export {
  roundUpToHour, roundDownToHour, roundToNearestHour, dateInPast
}
